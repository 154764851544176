import { Session, User } from '@prisma/client';
import Cookies from 'cookies';
import { addYears } from 'date-fns';
import { IncomingMessage, ServerResponse } from 'http';
import { NextApiRequest, NextApiResponse } from 'next';
import { v4 as uuid } from 'uuid';
import prisma from './prisma';

export interface NextApiSessionRequest extends NextApiRequest {
  session: Session & { user: User }
}

export declare type NextApiSessionHandler<T = any> = (req: NextApiSessionRequest, res: NextApiResponse<T>) => void | Promise<void>;

export const withSession = <T>(handler: NextApiSessionHandler<T>) => async (request: NextApiRequest, response: NextApiResponse) => {
  const sessionUuid = request.cookies['session.uuid'];

  if (sessionUuid) {
    const session = await prisma.session.findUnique({
      where: {
        uuid: sessionUuid
      },
      include: {
        user: true
      }
    });

    if (session) {
      const sessionRequest = request as NextApiSessionRequest;
      sessionRequest.session = session;
      handler(sessionRequest, response);
    } else {
      response.status(401).end();
    }
  } else {
    response.status(401).end();
  }
};

export const getOrCreateSession = async (request: IncomingMessage, response: ServerResponse) => {
  const cookies = new Cookies(request, response);
  const sessionUuid = cookies.get('session.uuid');

  const createSession = async () => {
    const saved = await prisma.session.create({
      data: {
        uuid: uuid(),
        user: {
          create: {
            welcomed: false
          }
        }
      }
    });

    cookies.set('session.uuid', saved.uuid, {
      expires: addYears(new Date(), 10)
    });

    return saved.uuid;
  }

  if (sessionUuid) {
    if (await prisma.session.count({ where: { uuid: sessionUuid } }) === 0) {
      return await createSession();
    }

    await prisma.$executeRaw`
      UPDATE "Session" SET "lastSeen" = now() WHERE uuid = ${sessionUuid}
    `;
  } else {
    return await createSession();
  }

  return sessionUuid;
};
