import { PrismaClient } from '@prisma/client';

declare global {
  var prisma: PrismaClient
}

let client = undefined as any as PrismaClient;

if (typeof window === 'undefined') {
  client = global.prisma || new PrismaClient({
    log: process.env.NODE_ENV === 'production' ? ['warn', 'error'] : ['query', 'info', 'warn', 'error']
  });

  if (process.env.NODE_ENV === 'development') {
    global.prisma = client;
  }
}

export default client;
