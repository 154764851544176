import { NextApiResponse } from 'next';
import { Data } from '../model';
import prisma from './prisma';
import { NextApiSessionRequest } from './withSession';

export const getEmptyData = () => ({
  empty: true,
  lastSaveUuid: null,
  welcomed: false,
  user: null,
  notesText: '',
  categories: [],
  blogPosts: [],
  days: []
});

export const getData = async (sessionUuid: string): Promise<Data> => {
  const session = (await prisma.session.findUnique({
    where: {
      uuid: sessionUuid
    },
    include: {
      user: {
        include: {
          blogPosts: true,
          days: {
            include: {
              products: true
            }
          }
        }
      }
    },
    rejectOnNotFound: true
  }))!;

  return {
    lastSaveUuid: session.user.lastSaveUuid,
    welcomed: session.user.welcomed,
    notesText: session.user.notesText,
    user: session.user.email ? {
      email: session.user.email,
      firstName: session.user.firstName!,
      lastName: session.user.lastName!,
      photoUrl: session.user.photoUrl!
    } : null,
    categories: await prisma.category.findMany(),
    blogPosts: session.user.blogPosts,
    days: session.user.days
  };
};

export const sendLogicalError = async (request: NextApiSessionRequest, response: NextApiResponse) =>
  response
    .setHeader('x-logical-error', 'true')
    .status(200)
    .json(await getData(request.session.uuid));
