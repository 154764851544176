import { NextPageContext } from 'next';
import { getData, getEmptyData } from './data';
import { getOrCreateSession } from './withSession';

export const getStaticProps = async () => ({
  props: getEmptyData()
});

export const getInitialProps = async ({ req, res }: NextPageContext) => {
  if (!req || !res) {
    return getEmptyData();
  }

  return await getData(await getOrCreateSession(req, res));
};
